.dx-field-label.ip-settings-field-label {
    width: 100px;
}

.dx-field-value.ip-settings-field-value {
    width: 100px;
}

.dx-field-value.ip-settings-field-value.dx-field-value-edit {
    background-color: rgba(173, 255, 171, 0.3);
}

.dx-field-value.ip-settings-field-value.dx-field-value-edit:hover {
    background-color: rgba(173, 255, 171, 0.5);
}

.ip-variant-item-fieldset {
    border: none;
    border-radius: 5px;
    padding: 5px;
    margin: -3px 0;
}

.ip-variant-item-fieldset.ip-variant-item-fieldset-match {
    border: 1px solid #03a9f4;
    border-radius: 5px;
    padding: 5px;
}

.ip-variant-item-fieldset-legend {
    color: #03a9f4;
}

.ip-variant-item {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-left: 10px;
}

.ip-variant-item-label {
    order: 1;
    flex-basis: 40px;
    flex-shrink: 0;
    align-self: flex-start;
    color: darkgrey;
}

.ip-variant-item-value {
    order: 2;
    flex-basis: 50px;
    flex-grow: 1;
    align-self: flex-start;
}
