.metric[role="columnheader"] {
  font-weight: 600;
  font-size: 30px;
  /*font-style: normal;*/
}

.metric {
  font-weight: 450;
  /*font-style: italic;*/
}

.metric-values {
  font-weight: 100;
  /*font-style: italic;*/
}

.metric-values[role="columnheader"] {
  font-weight: normal;
  font-style: normal;
}

.detail-toolbar {
  padding: 10px;
  /*padding-left: 20px;*/
  /*padding-right: 20px;*/
}

.header-comment {
  color: #b1b1b1;
}