.SalesPlanStatus.MuiChip-root {
    height: 20px;
    font-size: 0.7rem;
    border-radius: 7px;
    margin: 0 5px;
    vertical-align: middle;
}

.SalesPlanItem {
    margin-top: -3px;
    margin-bottom: -4px;
}

.SelectedSalesPlanItem {
  display: inline-block;
  padding-left: 50px;
  text-indent: 0;
  line-height: 30px;
  font-size: 15px;
  width: 100%;
}
